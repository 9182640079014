<template>
  <v-footer
    tile
    height="54"
    class="font-weight-medium shadow-sm ma-4 rounded-lg"
    :color="$vuetify.theme.dark ? 'dark' : 'white'"
    :dark="$vuetify.theme.dark"
    :inset="true"
  >
    <div class="d-flex align-center gap-2">
      <template v-if="!hidePoweredBy">
        <img
          v-if="$vuetify.theme.dark"
          height="12"
          alt="Voy Logo"
          src="/img/logo/voy-dark.png"
          class="unselectable non-draggable"
        />
        <img
          v-else
          height="12"
          alt="Voy Logo"
          class="unselectable non-draggable"
          src="/img/logo/voy-light.png"
        />
      </template>

      <v-chip v-if="config.opMode" color="warning" label small>
        Modo técnico
      </v-chip>
    </div>
    <v-spacer />
    <v-btn
      text
      x-small
      class="text--disabled"
      target="_blank"
      :href="`mailto:${email}`"
    >
      Contato
    </v-btn>
  </v-footer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { email, hidePoweredBy } from "@/themeConfig.js";
export default {
  data: () => ({
    email,
    hidePoweredBy,
  }),
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapGetters("auth", ["config"]),
  },
};
</script>
